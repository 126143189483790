@media (max-width: 1200px) {
  .aside {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .page-meta {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .navigation .inside,
  .sidebar {
    padding: 20px 0 0;
    height: 100vh;
  }
  .navigation,
  .sidebar {
    position: sticky;
    top: 0;
    z-index: 1020;
    width: 100%;
    border-right: 2px solid #fbfbfb;
  }
  .main,
  .main .start,
  .sidebar {
    height: 100vh;
  }
  .layout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main .start .content .callout,
  .sidebar .settings .categories .category:last-of-type {
    display: none;
  }
  .navigation .inside .menu .avatar-xl {
    margin-bottom: 50px;
  }

  .sidebar::-webkit-scrollbar {
    width: 5px;
  }
  .sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background: #f5f5f5;
  }
  .main .start .content form.signup {
    max-width: 466px;
  }
  .main .start .content .form-parent {
    display: flex;
  }
  .main .start .content .form-parent .form-group {
    margin-right: 15px;
  }
  .main .start .content .form-parent .form-group:last-child {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  /* body {
    padding-bottom: 54px;
  } */

  .aside,
  .navigation .inside .menu .avatar-xl,
  .navigation .inside .menu .mode,
  .navigation .inside .menu .power {
    display: none;
  }
  .navigation .inside .menu {
    flex-wrap: unset !important;
    align-items: flex-end;
    width: 100%;
  }
  .navigation .inside .menu a {
    margin-left: auto;
    margin-right: auto;
  }

  .main .start {
    padding: 73px 0 77px;
  }
  .main .start .content .button {
    margin-bottom: 20px;
  }
  .main .start .content .callout {
    display: block;
  }
  .main .chat .content {
    height: calc(100vh - 185px);
  }

  .navigation {
    position: fixed;
    z-index: 99;
    max-width: 100px;
    width: 100px;
    left: -100px;
    top: 0;
  }
  .navigation.active {
    left: 0;
  }
  .sidebar {
    max-width: 100%;
    width: 100%;
    padding: 30px 6px;
    transition: all 0.2s linear 0s;
    min-width: 100%;
  }
  .sidebar.slide {
    padding-left: 90px;
  }

  .main {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 999;

    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }

  .main.show {
    right: 0;
  }
  .main .chat .content .date {
    padding-top: 0;
  }

  .page-meta > h2 {
    font-size: 26px;
  }
  .page-meta {
    padding: 0;
  }
  .page-meta > span {
    font-size: 15px;
  }
  .register-content {
    padding: 20px;
  }
  .container.text-box{
    height: 100px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .main .chat .top {
    padding: 20px 6px;
    height: 85px;
  }
  .main .chat .top .inside .btn.back-to-mesg {
    display: block;
    margin-left: 10px;
    margin-right: 0;
  }
}

@media (max-width: 740px) {
  .sign-bg {
    height: 100%;
  }
  .page-meta {
    margin-bottom: 30px;
    padding: 0 20px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 468px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .requests .title {
    padding: 30px 20px;
  }
  .requests .content {
    padding: 34px 20px 40px;
  }
  .requests .content .user {
    left: 29px;
  }
  .main .start .content h1 {
    font-size: 36px;
  }

  .navigation {
    max-width: 70px;
    min-width: 70px;
    width: 70px;
  }
  .sidebar.slide {
    padding-left: 70px;
  }
  .navigation .inside > .nav.menu > a,
  .navigation .inside > .nav.menu > button {
    font-size: 9px;
  }

  .main .chat .content .message .text{
    max-width: 270px;
  }
}
